import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Text from "../components/blocks/text"
import ImageGallery from "../components/blocks/imagegallery"
import Soundcloud from "../components/blocks/soundcloud"
import Video from "../components/blocks/video"
import SEO from "../components/seo"

import "sanitize.css/sanitize.css"
import "../styles/main.css"

const FacilitatorPage = ({ data, context }) => {
  const { heading, subheading, blocks } = data.datoCmsFacilitator
  return (
    <Layout fillMenu>
      <SEO title={heading} />

      <div class="container mt-5 is-small is-text-center">
        <Link class="link h3" to="/lineup">
          &#x2190; Back to lineup
        </Link>
        <h1 class="heading mb-3 mt-5">{heading}</h1>
        <p class="subheading">{subheading}</p>
      </div>

      <div class="container is-padded mt-5">
        {blocks.map(block => {
          if (block.__typename === "DatoCmsVideo") {
            return (
              <div class="mb-3">
                <Video key={block.id} code={block.youtubeId}></Video>
              </div>
            )
          }
          if (block.__typename === "DatoCmsText") {
            return (
              <div class="mb-3">
                <Text
                  key={block.id}
                  heading={block.heading}
                  subheading={block.subheading}
                  body={block.body}
                ></Text>
              </div>
            )
          }
          if (block.__typename === "DatoCmsImageGallery") {
            return (
              <div class="mb-5">
                <ImageGallery
                  key={block.id}
                  images={block.images.map(image => ({
                    src: image.url,
                  }))}
                />
              </div>
            )
          }
          if (block.__typename === "DatoCmsSoundcloudTrack") {
            return (
              <div class="mb-5">
                <Soundcloud key={block.id} trackId={block.trackId} />
              </div>
            )
          }
          return null
        })}
      </div>
    </Layout>
  )
}

export default FacilitatorPage

export const query = graphql`
  query($url: String!) {
    datoCmsFacilitator(url: { eq: $url }) {
      url
      heading
      mainImage {
        url
      }
      blocks {
        ... on DatoCmsVideo {
          id
          youtubeId
          __typename
        }
        ... on DatoCmsText {
          id
          heading
          subheading
          body
          __typename
        }
        ... on DatoCmsImageGallery {
          id
          images {
            url
          }
          __typename
        }
        ... on DatoCmsSoundcloudTrack {
          id
          trackId
          __typename
        }
      }
    }
  }
`
