import React, { useState } from "react"
import Carousel, { Modal, ModalGateway } from "react-images"
import "./imagegallery.css"

export default function ImageGallery({ images }) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)

  function showLightbox(i) {
    setSelectedIndex(i)
    setModalIsOpen(true)
  }

  return (
    <div class="container is-small is-padded mt-5 mb-5">
      <h1 class="h2 mb-3">Photos</h1>
      <div class="image-gallery">
        {images.map((image, i) => {
          return (
            <img
              alt=""
              key={i}
              src={`${image.src}?q=27&auto=compress`}
              onClick={() => showLightbox(i)}
            ></img>
          )
        })}
      </div>
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={() => setModalIsOpen(false)}>
            <Carousel currentIndex={selectedIndex} views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}
